import { Box, Divider, Stack, Typography } from "@mui/material";
import { useMdOrUp } from "src/themes";

import { Carousel } from "../Carousel";
import { Stat } from "./Stat";
import {
  BIG_DESKTOP_STEP_HEIGHT,
  DESKTOP_STEP_HEIGHT,
  MOBILE_STEP_HEIGHT,
  StepDisplay,
} from "./StepDisplay";
import { STEPS } from "./config";

export const ReferencesSection = () => {
  const mdOrUp = useMdOrUp();

  return (
    <Stack
      gap={{
        xs: 4,
        md: 7.5,
      }}
      pt={{
        xs: 0,
        md: 7.5,
      }}
      mb={{
        xs: 7.5,
        md: 20,
      }}
      zIndex={2}
    >
      <Typography
        color="primary.main"
        variant="caption"
        textAlign="center"
        fontSize={{
          xs: 14,
          md: 16,
        }}
        fontWeight={600}
      >
        WHY PADRE
      </Typography>

      <Carousel
        count={mdOrUp ? 3 : STEPS.length}
        wrapperProps={{
          height: {
            xs: MOBILE_STEP_HEIGHT,
            md: DESKTOP_STEP_HEIGHT,
            xl: BIG_DESKTOP_STEP_HEIGHT,
          },
        }}
      >
        {STEPS.map((step, index) => (
          <StepDisplay key={index} {...step} />
        ))}
      </Carousel>

      {mdOrUp ? (
        <Stack direction="row" gap={2.5} justifyContent="center">
          <Stat value="$165M" label="Trading Vol" minWidth={200} />
          <Divider flexItem sx={{ width: 60, height: 30, opacity: 0.4 }} />
          <Stat value="+35K" label="Users" minWidth={200} />
          <Divider flexItem sx={{ width: 60, height: 30, opacity: 0.4 }} />
          <Stat value="+400K" label="Trades" minWidth={200} />
        </Stack>
      ) : (
        <Stack gap={2.5}>
          <Stack justifyContent="center" direction="row" gap={2.5}>
            <Box width={75}>
              <Stat value="$165M" label="Trading Vol" />
            </Box>

            <Divider flexItem sx={{ width: 20, height: 20, opacity: 0.4 }} />
            <Box width={75}>
              <Stat value="+35K" label="Users" />
            </Box>
          </Stack>
          <Stack alignItems="center">
            <Stat value="+400K" label="Trades" />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
