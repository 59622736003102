import { ReactNode } from "react";

import { Box, Stack, Typography } from "@mui/material";

import { GradientBox } from "../GradientBox";
import quote from "./quote.svg";

export type TStepDisplayProps = {
  text: string | ReactNode;
  imgSrc: string;
  link: string;
};

export const StepDisplay = ({ text, imgSrc, link }: TStepDisplayProps) => (
  <GradientBox
    height={{
      xs: MOBILE_STEP_HEIGHT,
      md: DESKTOP_STEP_HEIGHT,
      xl: BIG_DESKTOP_STEP_HEIGHT,
    }}
    minWidth={{
      xs: "calc(100% - 8px)",
      md: "calc(33% - 5px)",
      lg: "calc(33% - 4px)",
      xl: "calc(33% - 2px)",
    }}
    minHeight={{
      xs: MOBILE_STEP_HEIGHT,
      md: DESKTOP_STEP_HEIGHT,
      xl: BIG_DESKTOP_STEP_HEIGHT,
    }}
    py={0}
    innerBoxProps={{
      alignItems: "flex-start",
      justifyContent: "flex-start",
      pt: 2.75,
    }}
    px={{
      xs: 3.5,
      md: 6,
    }}
  >
    <Stack gap={2.5}>
      <Stack alignSelf="flex-start">
        <img src={quote} alt="quote" style={{ width: 17, height: 12 }} />
      </Stack>

      <Typography
        variant="body2"
        sx={{
          opacity: 0.6,
        }}
      >
        {text}
      </Typography>
      <Box
        component="a"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: AVATAR_SIZE,
          height: AVATAR_SIZE,
          borderRadius: "50%",
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
          "&:hover": {
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.05) 100%)",
          },
        }}
      >
        <img
          src={imgSrc}
          style={{
            height: ICON_SIZE,
            width: ICON_SIZE,
            borderRadius: "50%",
          }}
          alt="img"
        />
      </Box>
    </Stack>
  </GradientBox>
);

const AVATAR_SIZE = 44;
const ICON_SIZE = 32;
export const BIG_DESKTOP_STEP_HEIGHT = 260;
export const DESKTOP_STEP_HEIGHT = 310;
export const MOBILE_STEP_HEIGHT = 320;
