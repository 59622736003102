export enum EAppPath {
  TOS = "tos",
}
export const ROOT_APP_PATH = "/";
export const DEFAULT_APP_PATH = ROOT_APP_PATH;

export const HEADER_HEIGHT_MOBILE = 50;
export const HEADER_HEIGHT_DESKTOP_SCROLLED = 80;
export const HEADER_HEIGHT_DESKTOP = 149;
export const HEADER_Z_INDEX = 10;

export const SECTION_ID_FEATURES = "padre-features";

export const TWITTER_URL = "https://twitter.com/PadreApp";
export const DISCORD_URL = "https://discord.gg/padreapp";
export const DISCORD_TICKET_URL = "https://discord.gg/quxfdtVMxY";
export const TELEGRAM_URL = "https://t.me/padre_app";
export const YOUTUBE_URL = "https://www.youtube.com/@PadreApp";

export const APP_PADRE_URL = "https://trade.padre.gg";
export const APP_PADRE_TRENDING_URL = `${APP_PADRE_URL}/trending`;
export const APP_PADRE_TOKEN_URL = `${APP_PADRE_URL}/trade/eth/0x3b8f9be1c9d951e2caf224e8a698b61b64db0796`;
export const COINGECKO_PADRE_TOKEN_URL =
  "https://www.coingecko.com/en/coins/padre";
export const COINMARKETCAP_PADRE_TOKEN_URL =
  "https://coinmarketcap.com/currencies/padre/";

export const TOS_URL = `/tos`;
export const DOCS_URL = "https://docs.padre.gg";
export const FAQ_URL = `${DOCS_URL}/introduction/faq`;

export const REV_SHARE_DOSC_URL = `${DOCS_URL}/padre-v2/usdpadre-token/revenue-sharing`;
