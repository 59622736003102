import { Stack, Tooltip, Typography } from "@mui/material";
import baseIcon from "src/assets/chainIcons/base.svg";
import bscIcon from "src/assets/chainIcons/bsc.svg";
import ethIcon from "src/assets/chainIcons/eth.svg";
import solanaIcon from "src/assets/chainIcons/solana.svg";
import { ActionButton } from "src/components/ActionButton";
import { GradientBox } from "src/components/GradientBox";
import { GradientTypography } from "src/components/GradientTypography";
import { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from "src/config";
import { EButtonId } from "src/contexts/Mixpanel";
import { useMdOrUp, useLgOrUp, useXlOrUp } from "src/themes";

import { HeroVideoPlayer } from "./HeroVideoPlayer";

export const HeroSection = () => {
  const isMdOrUp = useMdOrUp();
  const isLgOrUp = useLgOrUp();
  const isXlOrUp = useXlOrUp();

  return (
    <Stack
      justifyContent="center"
      position="relative"
      pt={{
        xs: `${HEADER_HEIGHT_MOBILE}px`,
        md: `${HEADER_HEIGHT_DESKTOP}px`,
      }}
      mx={{
        xs: 0,
        md: -2.375,
      }}
      pb={{
        xs: 4,
        md: 10,
      }}
      height="100%"
    >
      <Stack
        direction={{
          xs: "column-reverse",
          lg: "row",
        }}
        justifyContent="center"
        alignItems="center"
        height="100%"
        gap={{
          xs: 2,
          lg: 1,
        }}
      >
        <HeroVideoPlayer />

        <GradientBox
          height="100%"
          width="100%"
          shadeStrength={0.8}
          {...(isMdOrUp ? {} : { px: 2, pt: 2 })}
          maxWidth={isLgOrUp ? 1000 : undefined}
          pb={{
            xs: 4,
            md: 2,
          }}
          px={{
            xs: 7.5,
            lg: 0,
          }}
        >
          <Stack
            height="100%"
            justifyContent="space-between"
            gap={{ xs: 11.5, xl: 13.5 }}
          >
            <Stack
              gap={isMdOrUp ? 7.5 : 3}
              alignItems={isMdOrUp ? "flex-start" : "center"}
            >
              <GradientTypography
                variant={isXlOrUp ? "h1" : isLgOrUp ? "h2" : "h1"}
                textAlign={isMdOrUp ? "start" : "center"}
                noWrap
              >
                Your Edge in
                <br /> Memecoin Trading
              </GradientTypography>

              <Typography
                variant="body1"
                color="text.disabled"
                textAlign={isMdOrUp ? "start" : "center"}
                sx={{ maxWidth: 500 }}
              >
                The fastest and most reliable memecoin trading terminal built
                for traders, by traders.
                <br /> Everything you need to win.
              </Typography>

              <ActionButton
                label="Trade Now with Padre"
                buttonId={EButtonId.HERO}
              />
            </Stack>

            <Stack gap={2.5}>
              <Typography
                variant="caption"
                textAlign={isMdOrUp ? "start" : "center"}
                sx={{ opacity: 0.4 }}
              >
                Available on
              </Typography>
              <Stack
                direction="row"
                gap={5}
                justifyContent={isMdOrUp ? "flex-start" : "center"}
              >
                <Tooltip title="Solana" {...commonTooltipProps}>
                  <img src={solanaIcon} alt="solana" />
                </Tooltip>
                <Tooltip title="Ethereum" {...commonTooltipProps}>
                  <img src={ethIcon} alt="eth" />
                </Tooltip>
                <Tooltip title="Base" {...commonTooltipProps}>
                  <img src={baseIcon} alt="base" />
                </Tooltip>
                <Tooltip title="BSC" {...commonTooltipProps}>
                  <img src={bscIcon} alt="bsc" />
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>
        </GradientBox>
      </Stack>
    </Stack>
  );
};

const commonTooltipProps = {
  disableInteractive: true,
  componentsProps: {
    tooltip: {
      sx: {
        background:
          "linear-gradient(180deg, #18181B 0%, rgba(24, 24, 27, 0.40) 100%)",
        borderRadius: "6px",
        border: "1px solid #464646",
        padding: "8px 12px",
      },
    },
  },
};
