import { useCallback } from "react";

import { Stack, Typography } from "@mui/material";
import { REV_SHARE_DOSC_URL } from "src/config";
import { useMixpanelContext, EButtonId } from "src/contexts/Mixpanel";
import { useMdOrUp } from "src/themes";

import { DecorativeButton } from "../DecorativeButton";
import { GradientTypography } from "../GradientTypography";
import { MAX_SECTION_WIDTH } from "../_pages/config";
import { RavSharingImage } from "./RavSharingImage";

export const RavSharingSection = () => {
  const mdOrUp = useMdOrUp();

  const { trackButtonClicked } = useMixpanelContext();

  const onClick = useCallback(() => {
    trackButtonClicked(EButtonId.ESSENCE);
  }, [trackButtonClicked]);

  return (
    <Stack
      width="100%"
      alignItems="center"
      alignSelf="center"
      maxWidth={MAX_SECTION_WIDTH}
    >
      <Stack
        width="100%"
        position="relative"
        direction={{
          xs: "column",
          md: "row",
        }}
        mt={{
          xs: 7.5,
          md: 0,
        }}
        py={{
          xs: 0,
          md: 15,
        }}
        px={{
          xs: 0,
          md: 5,
          lg: 10,
          xl: 12.5,
        }}
        gap={10.75}
        sx={{
          background:
            "linear-gradient(180deg, rgba(24, 24, 27, 0.00) 0%, #18181B 100%)",
          borderRadius: "6px",
          border: "1px solid rgba(255, 255, 255, 0.00)",
          borderTop: "none",
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          gap={{ xs: 3, md: 4 }}
          maxWidth={{
            md: 420,
            lg: 580,
            xl: 630,
          }}
          zIndex={2}
        >
          <Stack gap={1.25}>
            <Typography
              color="primary.main"
              variant="caption"
              fontSize={{
                xs: 14,
                md: 16,
              }}
              fontWeight={600}
            >
              HOLD, TRADE, EARN
            </Typography>
            <GradientTypography variant={mdOrUp ? "h4" : "h2"}>
              How we keep our traders
              <br /> & investors loyal
            </GradientTypography>
          </Stack>

          <Typography variant="body1" color="text.disabled">
            <span
              style={{
                color: "#FF7324",
              }}
            >
              $PADRE Revenue Sharing
            </span>{" "}
            is designed to reward loyal $PADRE holders while encouraging active
            traders who contribute to the app's growth. Bigger trading volume
            within the Padre app boosts your rewards even further.
          </Typography>

          <DecorativeButton
            onClick={onClick}
            href={REV_SHARE_DOSC_URL}
            sx={{
              maxWidth: 172,
              px: 2,
            }}
          >
            Learn More
          </DecorativeButton>
        </Stack>

        <RavSharingImage />
      </Stack>
    </Stack>
  );
};
