import { memo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { Stack, ThemeProvider } from "@mui/material";
import "src/config/customStyles.css";

import "./App.css";
import { SimpleRedirect } from "./components/SimpleRedirect";
import { Landing, ToS } from "./components/_pages";
import "./components/_pages/ToS";
import { DEFAULT_APP_PATH, EAppPath, ROOT_APP_PATH } from "./config";
import { MixpanelContextProvider } from "./contexts/Mixpanel";
import { theme } from "./themes";

/*
 * Version note: 0.15.21
 */

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <MixpanelContextProvider>
        <Stack minHeight="100vh">
          <Router>
            <AppRoutes />
          </Router>
        </Stack>
      </MixpanelContextProvider>
    </ThemeProvider>
  );
};

const AppRoutes = memo(() => {
  const location = useLocation();
  return (
    <Routes location={location}>
      <Route path={ROOT_APP_PATH} element={<Landing />} />
      <Route path={EAppPath.TOS} element={<ToS />} />

      <Route path="*" element={<SimpleRedirect path={DEFAULT_APP_PATH} />} />
    </Routes>
  );
});
