import { useCallback } from "react";

import { Divider, Stack, Typography } from "@mui/material";
import {
  APP_PADRE_TOKEN_URL,
  COINGECKO_PADRE_TOKEN_URL,
  COINMARKETCAP_PADRE_TOKEN_URL,
  REV_SHARE_DOSC_URL,
} from "src/config";
import { useMixpanelContext, EButtonId } from "src/contexts/Mixpanel";
import { useEnrichedUrl } from "src/hooks/useEnrichedUrl";

import { DecorativeButton } from "../DecorativeButton";
import { ExternalTextLink } from "../ExternalLink";
import { ExternalLinkBehavior } from "../ExternalLink/ExternalLinkBehavior";
import { GradientTypography } from "../GradientTypography";
import { MAX_SECTION_WIDTH } from "../_pages/config";
import { InfoSection } from "./InfoSection";
import { TokenSectionTable } from "./TokenSectionTable";
import coingeckoIcon from "./icons/coingecko.svg";
import coinmarketIcon from "./icons/coinmarket.svg";
import padreIcon from "./icons/padre.svg";
import padreFullIcon from "./icons/padreFull.png";

export const PadreTokenSection = () => {
  const enrichedUrl = useEnrichedUrl(APP_PADRE_TOKEN_URL);
  const { trackButtonClicked } = useMixpanelContext();

  const onClick = useCallback(() => {
    trackButtonClicked(EButtonId.TOKEN);
  }, [trackButtonClicked]);

  return (
    <Stack
      alignSelf="center"
      maxWidth={MAX_SECTION_WIDTH}
      gap={{
        xs: 4,
        md: 15,
      }}
      my={{
        xs: 7.5,
        md: 20,
      }}
    >
      <Stack alignItems="center" gap={1.25}>
        <Typography
          color="primary.main"
          variant="caption"
          textAlign="center"
          fontSize={{
            xs: 14,
            md: 16,
          }}
          fontWeight={600}
        >
          TOKEN
        </Typography>

        <GradientTypography variant="h1">$padre token</GradientTypography>
      </Stack>

      <Stack
        gap={{
          xs: 4,
          md: 7.5,
        }}
      >
        <Divider
          flexItem
          sx={{
            ":before": {
              borderColor: "#FFF",
              opacity: 0.1,
            },
            ":after": {
              borderColor: "#FFF",
              opacity: 0.1,
            },
          }}
        >
          <img src={padreFullIcon} alt="padreFull" />
        </Divider>

        <Stack
          gap={{
            xs: 6,
            md: 6.25,
          }}
          mb={{
            xs: 2,
            md: 0,
          }}
          alignItems="center"
        >
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            justifyContent="space-between"
            alignItems="center"
            gap={6}
          >
            <InfoSection
              titles={
                <>
                  revenue
                  <br /> sharing
                </>
              }
              text={
                <>
                  {
                    "This utility is designed to reward loyal $PADRE holders while encouraging active traders who contribute to the app's growth. "
                  }
                  <ExternalTextLink
                    label="Learn more"
                    href={REV_SHARE_DOSC_URL}
                    nofollow={false}
                    sx={{
                      color: "#9B9B9B",
                      textDecorationColor: "#9B9B9B",
                      textUnderlineOffset: "2px",
                    }}
                  />
                </>
              }
            />
            <InfoSection
              titles={
                <>
                  No token
                  <br />
                  fees
                </>
              }
              text="On July 24th, the team announced that token trading taxes have been reduced from 2% to 0%."
              maxWidth={{ xs: undefined, md: 295 }}
            />
            <InfoSection
              titles={
                <>
                  Buybacks
                  <br />& burns
                </>
              }
              text="$PADRE buybacks and burns are implemented to reward holders and celebrate milestones."
            />
          </Stack>

          <DecorativeButton onClick={onClick} href={enrichedUrl}>
            Buy $PADRE
          </DecorativeButton>
        </Stack>
      </Stack>

      <Stack
        alignItems="center"
        gap={{
          xs: 3,
          md: 5,
        }}
      >
        <GradientTypography variant="h5">
          Hold $padre and reduce in-app fees
        </GradientTypography>
        <TokenSectionTable />
      </Stack>

      <Stack
        direction={{
          xs: "column",
          md: "row",
        }}
        justifyContent="center"
        alignItems="center"
        gap={{
          xs: 2,
          md: 7.5,
        }}
      >
        <Stack gap={2} direction="row">
          <ExternalLinkBehavior href={COINMARKETCAP_PADRE_TOKEN_URL}>
            <img src={coinmarketIcon} alt="coinmarket" />
          </ExternalLinkBehavior>
          <ExternalLinkBehavior href={COINGECKO_PADRE_TOKEN_URL}>
            <img src={coingeckoIcon} alt="coingecko" />
          </ExternalLinkBehavior>
          <ExternalLinkBehavior href={APP_PADRE_TOKEN_URL}>
            <img src={padreIcon} alt="padre" />
          </ExternalLinkBehavior>
        </Stack>
        <Stack gap={0.25}>
          <Typography
            variant="body2"
            textAlign={{
              xs: "center",
              md: "start",
            }}
            fontSize={14}
          >
            Contract address
          </Typography>
          <Typography variant="body2" color="text.disabled" fontSize={14}>
            0xb36cf340a35f9860d0bb59afb0355580f0000dad
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
