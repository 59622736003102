import { Box } from "@mui/material";
import { useMdOrUp } from "src/themes";

import revSharingImage from "./images/rev-sharing.png";

export const RavSharingImage = () => {
  const mdOrUp = useMdOrUp();

  const content = (
    <Box
      component="img"
      src={revSharingImage}
      alt="rev-sharing"
      position={mdOrUp ? "absolute" : "relative"}
      zIndex={1}
      {...(mdOrUp
        ? {
            bottom: -125,
            right: -50,
          }
        : {
            width: 778,
            height: "auto",
            overflow: "hidden",
          })}
    />
  );

  if (!mdOrUp) {
    return (
      <Box
        sx={{
          width: 778,
          height: 600,
          overflow: "hidden",
          position: "relative",
        }}
      >
        {content}
      </Box>
    );
  }

  return content;
};
